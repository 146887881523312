import React from 'react'

import Typography from '@mui/material/Typography'

export type ParagraphProps = BASE.Helpers.IReactDefaultProps & {
  level?: number
  id?: string
  html?: string
  variant?: 'body1' | 'body2'
  component?: 'p' | 'span'
}

export default function Paragraph({
  id,
  children,
  html,
  variant = 'body1',
  component = 'p',
  sx,
}: ParagraphProps) {
  return (
    <Typography
      id={id}
      component={component}
      variant={variant}
      gutterBottom={false}
      dangerouslySetInnerHTML={html ? { __html: html } : undefined}
      sx={[
        (theme) => ({
          display: 'block',
          marginBottom: theme.spacing(5),
          '& + ul': {
            marginTop: theme.spacing(-3),
            [theme.breakpoints.up('lg')]: {
              marginTop: theme.spacing(-5),
            },
          },
          [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(8),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  )
}
