import React from 'react'
import { Box, useTheme } from '@mui/material'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'

export type ImageProps = BASE.Helpers.IReactDefaultProps & {
  image: BASE.Contentful.IAssetImage
  imageMobile?: BASE.Contentful.IAssetImage
  eager?: boolean
  objectFit?: 'contain' | 'fill' | 'none' | 'cover' | 'scale-down' | undefined
  objectPosition?: string | undefined
  position?: 'static' | 'relative'
  backgroundColor?: 'string'
  ariaHidden?: boolean
}

/**
 * Image
 */
export default function Image({
  image,
  imageMobile,
  eager,
  position = 'relative',
  objectFit,
  objectPosition,
  backgroundColor,
  className,
  sx,
  ariaHidden = false,
}: ImageProps) {
  if (!image.localFile) return null

  const theme = useTheme()

  if (image.localFile?.childImageSharp?.gatsbyImageData) {
    const images =
      !!imageMobile?.localFile?.childImageSharp?.gatsbyImageData &&
      withArtDirection(image.localFile.childImageSharp.gatsbyImageData, [
        {
          media: `(max-width: ${theme.breakpoints.values.md}px)`,
          image: imageMobile.localFile.childImageSharp.gatsbyImageData,
        },
      ])
    return (
      <Box
        className={className}
        component={GatsbyImage}
        sx={[
          { width: '100%', height: '100%' },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        image={images || image.localFile.childImageSharp.gatsbyImageData}
        title={image.title || ''}
        alt={image.description || ''}
        loading={eager ? 'eager' : 'lazy'}
        style={{ position }}
        objectFit={objectFit}
        objectPosition={objectPosition}
        backgroundColor={backgroundColor}
        aria-hidden={ariaHidden}
      />
    )
  } else if (image.localFile?.extension === 'svg') {
    return (
      <Box
        className={className}
        component="picture"
        sx={[
          { width: '100%', height: '100%' },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {!!imageMobile?.localFile?.publicURL && (
          <source
            srcSet={imageMobile?.localFile?.publicURL}
            media={`(max-width: ${theme.breakpoints.values.md}px)`}
          />
        )}
        <Box
          role="img"
          component="img"
          aria-hidden={ariaHidden}
          loading={eager ? 'eager' : 'lazy'}
          sx={{ width: '100%' }}
          src={image.localFile.publicURL}
          title={image.title}
          alt={image.description}
        />
      </Box>
    )
  } else {
    console.error(
      `Image with title "${image.title}" could not be rendered, due to unknown type. Only JPG, PNG and SVG are allowed.`
    )
    return null
  }
}
