/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText as origRenderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import parse from 'html-react-parser'
import { Box, Typography } from '@mui/material'
import { ModuleUnion } from './PageModuleParser'

import PageModule from '@system/utils/PageModule'

import Paragraph from '@components/text/paragraph'
import List, { ListItem } from '@components/text/list'
import Link from '@components/core/link'
import Icon from '@components/core/icon'

export default function renderRichText(
  richText: BASE.Contentful.IRichtext<ModuleUnion>
) {
  return origRenderRichText(
    richText as RenderRichTextData<ContentfulRichTextGatsbyReference>,
    {
      renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
        [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
      },
      renderText: (text: string) => {
        return parse(
          text
            .replaceAll('\n', '<br>')
            .replaceAll('®', '<sup>®</sup>')
            .replaceAll('©', '<sup>©</sup>')
            .replaceAll('℗', '<sup>℗</sup>')
        )
      },
      renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <Paragraph>{children}</Paragraph>
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return (
            <Typography
              // @ts-expect-error "Value does not exist", but it does exist...
              id={node.content?.[0].value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()}
              variant="h1"
            >
              {children}
            </Typography>
          )
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return (
            <Typography
              // @ts-expect-error "Value does not exist", but it does exist...
              id={node.content?.[0].value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()}
              variant="h2"
            >
              {children}
            </Typography>
          )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return (
            <Typography
              // @ts-expect-error "Value does not exist", but it does exist...
              id={node.content?.[0].value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()}
              variant="h3"
              sx={{ mb: { xs: 6, xl: 8 } }}
            >
              {children}
            </Typography>
          )
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          return (
            <Typography
              // @ts-expect-error "Value does not exist", but it does exist...
              id={node.content?.[0].value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()}
              variant="h4"
              sx={{ mb: { xs: 2, xl: 4 } }}
            >
              {children}
            </Typography>
          )
        },
        [BLOCKS.HEADING_5]: (node, children) => {
          return (
            <Typography
              // @ts-expect-error "Value does not exist", but it does exist...
              id={node.content?.[0].value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()}
              variant="h5"
            >
              {children}
            </Typography>
          )
        },
        [BLOCKS.UL_LIST]: (node, children) => {
          return <List type="ul">{children}</List>
        },
        [BLOCKS.OL_LIST]: (node, children) => {
          return (
            <List type="ol" sx={{ counterReset: 'section 0' }}>
              {children}
            </List>
          )
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
          return <ListItem>{children}</ListItem>
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          if (node.data.target) {
            return (
              <PageModule
                key={`${node.data.target.id}`}
                pagemodule={node.data.target}
              />
            )
          }
          console.error('Missing embedded entry', node)
          return null
        },
        // eslint-disable-next-line react/display-name
        [INLINES.HYPERLINK]: (node, children) => {
          const isInternalLink = node.data.uri.includes(
            'digitalstrategie-deutschland.de'
          )
          return (
            <Link
              to={node.data.uri}
              isExternal={isInternalLink ? false : true}
              target={
                node.data.uri.includes('#')
                  ? '_self'
                  : /^(?!(tel:|fax:|javascript:).*).*/g.test(node.data.uri)
                  ? undefined
                  : '_self'
              }
            >
              <Box tabIndex={0} display="inline">
                {children}
              </Box>
              {
                <Icon
                  name={`${isInternalLink ? 'ArrowRight' : 'ArrowExternal'}`}
                  sx={{ height: '8px', width: '8px', margin: '0 3px 0 5px' }}
                />
              }
            </Link>
          )
        },
        // eslint-disable-next-line react/display-name
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const link = node.data?.target?.fields?.fullPath
          return link ? (
            <Link to={link}>
              <Box tabIndex={0} display="inline">
                {children}
              </Box>
              {
                <Icon
                  name={`${
                    node.data?.target?.fields?.isExternal
                      ? 'ArrowExternal'
                      : 'ArrowRight'
                  }`}
                  sx={{ height: '8px', width: '8px', margin: '0 3px 0 5px' }}
                />
              }
            </Link>
          ) : (
            children
          )
        },
        // eslint-disable-next-line react/display-name
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          if (node.data.target) {
            return (
              <Link to={node.data.target.localFile.publicURL} isExternal>
                <Box tabIndex={0} display="inline">
                  {children}
                </Box>
                {
                  <Icon
                    name="Download"
                    sx={{ height: '8px', width: '8px', margin: '0 3px 0 5px' }}
                  />
                }
              </Link>
            )
          }
          console.error('Missing asset hyperlink', node)
          return null
        },
      },
    }
  )
}
