import React from 'react'
import { Box, Typography } from '@mui/material'
import { M001StageProps } from '..'
import { useIntl } from 'react-intl'

export default function WithOnlyText({
  headline,
  optionalHeadline,
  tag,
  subtitle,
}: M001StageProps) {
  const intl = useIntl()
  return (
    <Box
      component="section"
      role="region"
      aria-label={intl.formatMessage({ id: 'stage.withOnlyText.ariaLabel' })}
      sx={(theme) => ({
        position: 'relative',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(20),
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(16),
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: theme.spacing(20),
        },
      })}
    >
      <Box
        sx={[
          (theme) => ({
            position: 'relative',
            width: '95%',
            backgroundColor: theme.palette.background.red,
            boxShadow: theme.boxShadows.stage,
            color: theme.palette.text.inverted,
            borderRadius: '0px 138px 138px 0px',
            padding: theme.spacing(18, 16, 18, 4),
            zIndex: 10,
            [theme.breakpoints.up('md')]: {
              width: '75%',
            },
            [theme.breakpoints.up('lg')]: {
              width: '50%',
              padding: !!optionalHeadline
                ? theme.spacing(16, 20, 16, 4)
                : theme.spacing(22, 20, 22, 4),
            },
          }),
        ]}
      >
        {!!tag && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              transform: 'translateY(-50%)',
            }}
          >
            <Typography
              component="p"
              variant="navigation"
              sx={(theme) => ({
                position: 'relative',
                zIndex: 10,
                marginBottom: 0,
                py: 2,
                paddingLeft: 4,
                paddingRight: '30%',
                marginLeft: 'auto',
                maxWidth: theme.maxWidth.lg / 2 + 96, // 80 is the paddingRight of the big red bar + padding left of this element itself
              })}
            >
              {tag}
            </Typography>
            <Box
              sx={(theme) => ({
                position: 'absolute',
                zIndex: 5,
                left: 0,
                top: 0,
                bottom: 0,
                width: '70%',
                backgroundImage: theme.palette.gradient.primary.leftright,
                backgroundSize: '130% 100%',
                borderRadius: '0px 138px 138px 0px',
              })}
            />
          </Box>
        )}
        <Box
          sx={(theme) => ({
            maxWidth: theme.maxWidth.lg / 2,
            [theme.breakpoints.up('lg')]: {
              width: '70%',
              maxWidth: '100%',
            },
            marginLeft: 'auto',
            marginBottom: 0,
          })}
        >
          <Typography
            variant="h1"
            sx={(theme) => ({
              mb: !!subtitle ? 4 : 0,
              [theme.breakpoints.up('lg')]: {
                mb: !!subtitle ? 5 : 0,
              },
            })}
          >
            {headline}
          </Typography>
          {!!optionalHeadline && (
            <Typography
              variant="h1"
              sx={(theme) => ({
                mb: !!subtitle ? 4 : 0,
                [theme.breakpoints.up('lg')]: {
                  mb: !!subtitle ? 5 : 0,
                },
              })}
            >
              {optionalHeadline}
            </Typography>
          )}
          <Typography variant="body2" sx={{ maxWidth: '90%' }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          position: 'relative',
          width: '95%',
          background: theme.palette.gradient.primary.topdown,
          boxShadow: theme.boxShadows.stage,
          color: theme.palette.text.inverted,
          borderRadius: '138px 0px 0px 138px',
          padding: theme.spacing(22),
          marginLeft: 'auto',
          marginTop: -28,
          zIndex: 5,
          [theme.breakpoints.up('md')]: {
            width: '60%',
          },
          [theme.breakpoints.up('lg')]: {
            marginTop: -34,
            width: '60%',
            padding: theme.spacing(28),
          },
        })}
      />
    </Box>
  )
}
