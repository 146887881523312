import React from 'react'
import { Box, Typography } from '@mui/material'
import Image from '@components/media/image'
import { M001StageProps } from '..'
import { useIntl } from 'react-intl'

export default function WithImage({
  headline,
  optionalHeadline,
  subtitle,
  tag,
  mediaDesktop,
  mediaMobile,
}: M001StageProps) {
  const intl = useIntl()
  return (
    <Box
      component="section"
      role="region"
      aria-label={intl.formatMessage({ id: 'stage.withImage.ariaLabel' })}
      sx={(theme) => ({
        position: 'relative',
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(20),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          paddingTop: '57.40%',
          overflow: 'hidden',
          [theme.breakpoints.up('lg')]: {
            paddingTop: '34.72%',
          },
        })}
      >
        {!!mediaDesktop && (
          <Image
            eager
            objectFit="cover"
            image={mediaDesktop}
            imageMobile={mediaMobile}
            sx={{
              position: 'absolute !important',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          position: 'relative',
          marginTop: '-10%',
          width: '95%',
          backgroundColor: theme.palette.background.red,
          boxShadow: theme.boxShadows.stage,
          color: theme.palette.text.inverted,
          borderRadius: '0px 138px 138px 0px',
          padding: theme.spacing(14, 16, 14, 4),
          zIndex: 5,
          [theme.breakpoints.up('md')]: {
            width: '75%',
          },
          [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            width: '50%',
            bottom: '20%',
            padding: !!optionalHeadline
              ? theme.spacing(16, 20, 16, 4)
              : theme.spacing(22, 20, 22, 4),
          },
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            transform: 'translateY(-50%)',
          }}
        >
          {!!tag && (
            <Typography
              component="p"
              variant="navigation"
              sx={(theme) => ({
                position: 'relative',
                zIndex: 10,
                marginBottom: 0,
                py: 2,
                paddingLeft: 4,
                paddingRight: '30%',
                marginLeft: 'auto',
                maxWidth: theme.maxWidth.lg / 2 + 96, // 80 is the paddingRight of the big red bar + padding left of this element itself
              })}
            >
              {tag}
            </Typography>
          )}
          <Box
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 5,
              left: 0,
              top: 0,
              bottom: 0,
              width: '70%',
              height: tag ? 'auto' : '28px',
              backgroundImage: theme.palette.gradient.primary.leftright,
              backgroundSize: '130% 100%',
              borderRadius: '0px 138px 138px 0px',
              [theme.breakpoints.up('lg')]: {
                height: tag ? 'auto' : '36px',
              },
            })}
          />
        </Box>

        <Box
          sx={(theme) => ({
            maxWidth: theme.maxWidth.lg / 2,
            [theme.breakpoints.up('lg')]: {
              width: '70%',
              maxWidth: '100%',
            },
            marginLeft: 'auto',
            marginBottom: 0,
          })}
        >
          <Typography
            variant="h1"
            sx={(theme) => ({
              mb: !!subtitle ? 4 : 0,
              [theme.breakpoints.up('lg')]: {
                mb: !!subtitle ? 5 : 0,
              },
            })}
          >
            {headline}
          </Typography>
          {!!optionalHeadline && (
            <Typography
              variant="h1"
              sx={(theme) => ({
                mb: !!subtitle ? 4 : 0,
                [theme.breakpoints.up('lg')]: {
                  mb: !!subtitle ? 5 : 0,
                },
              })}
            >
              {optionalHeadline}
            </Typography>
          )}
          <Typography variant="body2" sx={{ maxWidth: '90%' }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
