import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import IconButton from '@components/core/IconButton'
import Image from '@components/media/image'
import Video from '@components/media/video'
import { M001StageProps } from '..'
import PopUpVideo from '@components/media/popUpVideo'
import { useIntl } from 'react-intl'

export default function WithVideo({
  headline,
  buttonLabel,
  mediaDesktop,
  mediaMobile,
  mediaVideo,
}: M001StageProps) {
  const [triggerVideoPlay, setTriggerVideoPlay] = useState(false)
  const [triggerBackgroundVideoPlay, setTriggerBackgroundVideoPlay] =
    useState(true)
  const intl = useIntl()
  return (
    <Box
      component="section"
      role="region"
      aria-label={intl.formatMessage({ id: 'stage.withVideo.ariaLabel' })}
      sx={(theme) => ({
        position: 'relative',
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(20),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          height: '50vh',
          overflow: 'hidden',
          marginBottom: -30,
          '*::-webkit-media-controls-play-button': {
            display: 'none!important',
            webkitAppearance: 'none',
          },
          '*::-webkit-media-controls-start-playback-button': {
            display: 'none!important',
            webkitAppearance: 'none',
          },
          [theme.breakpoints.up('lg')]: {
            height: '100%',
            maxWidth: '1920px',
            margin: '0 auto',
          },
        })}
      >
        {!!mediaDesktop && mediaDesktop.mimeType.includes('image') && (
          <Image
            ariaHidden={true}
            eager
            objectFit="cover"
            image={mediaDesktop}
            imageMobile={mediaMobile}
            sx={{
              display: 'contents',
            }}
          />
        )}
        {!!mediaDesktop?.localFile &&
          mediaDesktop.mimeType.includes('video') && (
            <>
              <Video
                tabIndex={-1}
                src={mediaDesktop.localFile.publicURL}
                srcMobile={mediaMobile?.localFile?.publicURL}
                loop
                autoplay
                muted
                playsInline
                triggerPlay={triggerBackgroundVideoPlay}
                sx={(theme) => ({
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                  borderRadius: '16px 16px 30px',
                  [theme.breakpoints.up('md')]: {
                    width: '100%',
                  },
                })}
              />
              <IconButton
                tabIndex={0}
                name={triggerBackgroundVideoPlay ? 'Pause' : 'Play'}
                onClick={() =>
                  setTriggerBackgroundVideoPlay(!triggerBackgroundVideoPlay)
                }
                sx={(theme) => ({
                  cursor: 'auto',
                  position: 'absolute',
                  width: 'fit-content',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'start',
                  top: '25px',
                  right: '25px',
                  '&:hover': {
                    '& .MuiBox-root': {
                      backgroundPosition: '0 0%',
                      '& .MuiSvgIcon-root': {
                        color: '#FFF',
                        '& path': {
                          fill: theme.palette.text.inverted,
                        },
                      },
                    },
                  },
                  '& .MuiBox-root': {
                    minWidth: '40px',
                    minHeight: '40px',
                    backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.black} 80%)`,
                    backgroundSize: '100% 300%',
                    backgroundPosition: '0 100%',
                    '& .MuiSvgIcon-root': {
                      height: '0.7em',
                      '& polygon': {
                        fill: theme.palette.text.inverted,
                      },
                    },
                  },
                  [theme.breakpoints.up('lg')]: {
                    alignItems: 'end',
                    paddingBottom: '25px',
                    height: 'fit-content',
                    bottom: '25px',
                    top: 'unset',
                  },
                })}
              />
            </>
          )}
      </Box>
      <Box
        sx={(theme) => ({
          position: 'relative',
          width: '95%',
          backgroundColor: theme.palette.background.red,
          boxShadow: theme.boxShadows.stage,
          color: theme.palette.text.inverted,
          borderRadius: '0px 138px 138px 0px',
          padding: theme.spacing(10, 16, 10, 4),
          [theme.breakpoints.up('md')]: {
            width: '75%',
          },
          [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '50%',
            padding: theme.spacing(13, 20, 13, 4),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            maxWidth: theme.maxWidth.lg / 2,
            marginLeft: 'auto',
          })}
        >
          <Typography variant="h1" sx={{ marginBottom: 6 }}>
            {headline}
          </Typography>
          <Box
            component="button"
            onClick={() => setTriggerVideoPlay(!triggerVideoPlay)}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: theme.palette.text.inverted,
              '& .MuiTypography-root': {
                transition: '0.2s',
              },
              [theme.breakpoints.up('lg')]: {
                '&:hover': {
                  '& .MuiTypography-root': {
                    '& .MuiBox-root': {
                      backgroundPosition: '0 100%',
                    },
                    color: theme.palette.common.yellow[500],
                  },
                },
              },
            })}
          >
            <IconButton
              tabIndex={0}
              name="Play"
              hoverInvert
              sx={{ marginRight: 4 }}
            />
            <Typography
              component="span"
              variant="navigation"
              sx={{ textAlign: 'left' }}
            >
              {buttonLabel}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!!mediaVideo?.localFile && (
        <PopUpVideo
          media={mediaVideo}
          triggerVideoPlay={triggerVideoPlay}
          handleVideoClickToClose={() => setTriggerVideoPlay(false)}
        />
      )}
    </Box>
  )
}
