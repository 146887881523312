import React, { ReactElement } from 'react'

export type MetatagsProps = {
  metaTitle?: string
  metaDescription?: string
  metaOgImage?: string | null
  canonical?: string
  noIndex?: boolean | null
}

export default function Metatags({
  metaTitle,
  metaDescription,
  metaOgImage,
  canonical,
  noIndex,
}: Readonly<MetatagsProps>): ReactElement {
  return (
    <React.Fragment>
      {metaTitle && <title>{metaTitle}</title>}

      {metaTitle && <meta property="og:title" content={metaTitle} />}

      {metaDescription && (
        <React.Fragment>
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
        </React.Fragment>
      )}

      <meta name="twitter:card" content="summary" />

      {metaOgImage && (
        <React.Fragment>
          <meta property="og:image" content={metaOgImage} />
          <meta property="og:image:secure" content={metaOgImage} />
          <meta name="twitter:image" content={metaOgImage} />
        </React.Fragment>
      )}

      {canonical && <link rel="canonical" href={canonical} />}

      {noIndex && <meta name="robots" content="noindex" />}
    </React.Fragment>
  )
}
