import React from 'react'

import { Box, Typography } from '@mui/material'

export type ListProps = BASE.Helpers.IReactDefaultProps & {
  type?: 'ol' | 'ul'
}

export default function List({ type = 'ul', children, sx }: ListProps) {
  return (
    <Typography
      component={type}
      sx={[
        (theme) => ({
          li: {
            position: 'relative',
            marginBottom: '16px',
            listStyleType: 'none',
          },
          'li::before': {
            position: 'absolute',
            content: type === 'ol' && 'counter(section)',
            display: 'flex',
            width: '32px',
            left: '-46px',
            top: '-2px',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '20px',
            margin: 0,
            justifyContent: 'center',
            counterIncrement: 'section',
          },
          marginBottom: theme.spacing(6),
          position: 'relative',
          paddingLeft: theme.spacing(12),
          [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(10),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  )
}

export function ListItem({ children }: BASE.Helpers.IReactDefaultProps) {
  return (
    <Box
      component="li"
      sx={{
        marginBottom: 1,
        '& .MuiTypography-root': { marginBottom: 0 },
        '::after': {
          position: 'absolute',
          content: '""',
          display: 'block',
          width: '32px',
          height: '32px',
          left: '-46px',
          top: '-2px',
          background: 'rgb(225, 20, 20)',
          margin: 0,
          borderRadius: '50%',
          zIndex: -1,
        },
      }}
    >
      {children}
    </Box>
  )
}
