import React from 'react'
import WithVideo from './variants/withVideo'
import WithImage from './variants/withImage'
import WithOnlyText from './variants/withOnlyText'

export type M001StageProps = BASE.Helpers.ModuleDefaultProps & {
  headline?: string
  optionalHeadline?: string
  subtitle?: string
  tag?: string
  buttonLabel?: string
  mediaDesktop?: BASE.Contentful.IAssetImage
  mediaMobile?: BASE.Contentful.IAssetImage
  mediaVideo?: BASE.Contentful.IAsset
}

export default function M001Stage(stageProps: M001StageProps) {
  if (
    stageProps.headline &&
    stageProps.mediaDesktop &&
    stageProps.mediaVideo &&
    stageProps.buttonLabel
  ) {
    return <WithVideo {...stageProps} />
  } else if (stageProps.headline && stageProps.mediaDesktop) {
    return <WithImage {...stageProps} />
  }
  return <WithOnlyText {...stageProps} />
}
